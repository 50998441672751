<template >
    <div class="location">
      <div class="deviceList" :class="hasCollapse?'collapseList':''">
        <h4 v-if="!this.showGroup" class="title">
          <el-row>
            <el-col class="totalText" :span="6">{{totalDev}}</el-col>
            <el-col class="offlineText" :span="6">{{offlineDev}}</el-col>
            <el-col class="onlineText" :span="6">{{onlineDev}}</el-col>
            <el-col class="runText" :span="6">{{runDev}}</el-col>
          </el-row>
        </h4>
        <div class="searchInput">
          <el-input class="searchImei" :placeholder="$t('welcome.page.left.searchPlaceholder')" suffix-icon="el-icon-search"
                    v-model="searchDeviceImei"
                    @change="searchHandle" @blur="searchHandle">
          </el-input>
        </div>
        <div class="searchInput tabWrap">
          <el-tabs class="allTree" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="$t('welcome.page.left.labelAll')" name="all">
              <el-tree
                ref="mTree"
                :data="allMenuList"
                node-key="key"
                :accordion="false"
                :check-on-click-node="false"
                default-expand-all
                :show-checkbox="true"
                @check="selectNode"
                @node-click="clickNode">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <el-col :span="2">
                    <span class="itemClass">
                      <img class="treeIcon" :src="data.type === 1 ? dealerIcon : deviceIcon" alt="">
                      {{data.title}}
                      <el-link type="primary" icon="el-icon-edit" class="el-dropdown-link operation" :underline="false" v-if="data.type === 1" @click="toSoldDeviceManage(data)">{{$t('devicePage.option.edit')}}</el-link>
                      <!-- 下拉操作列表 -->
                      <div v-if="data.type === 2">
                      <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link operation">
                          {{$t('welcome.page.left.operation')}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="{ cmd: 'realTimeTracking', imei: data.title }">{{$t('welcome.page.left.realTimeTracking')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'historicalTrack', imei: data.title }">{{$t('welcome.page.left.historicalTrack')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'flightTraining', imei: data.title }">{{$t('welcome.page.left.flightTraining')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'modifyConfig', imei: data.title }">{{$t('devicePage.config')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'circularFence', imei: data.title }">{{$t('welcome.page.left.circularFence')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'polygonalFence', imei: data.title }">{{$t('welcome.page.left.polygonalFence')}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    </span>
                  </el-col>
                  <el-col :span="2">
                    <div v-if="data.deviceImei !== undefined" :class="getColor(data)"></div>
                  </el-col>
                  <el-col :span="10" >
                    <span style="text-align: left; "> {{ node.label }}</span>
                  </el-col>
              </span>
              </el-tree>
            </el-tab-pane>
            <el-tab-pane :label="$t('device.available')" name="available">
              <el-tree
                ref="mAvailableTree"
                :data="onlineMenuList"
                node-key="key"
                :accordion="false"
                :check-on-click-node="false"
                default-expand-all
                :show-checkbox="true"
                @check="selectNode"
                @node-click="clickNode">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <el-col :span="2">
                    <span class="itemClass">
                      <img class="treeIcon" :src="data.type === 1 ? dealerIcon : deviceIcon" alt="">
                      {{data.title}}
                      <el-link type="primary" icon="el-icon-edit" class="el-dropdown-link operation" :underline="false" v-if="data.type === 1" @click="toSoldDeviceManage(data)">{{$t('devicePage.option.edit')}}</el-link>
                      <!-- 下拉操作列表 -->
                      <div v-if="data.type === 2">
                      <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link operation">
                          {{$t('welcome.page.left.operation')}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="{ cmd: 'realTimeTracking', imei: data.title }">{{$t('welcome.page.left.realTimeTracking')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'historicalTrack', imei: data.title }">{{$t('welcome.page.left.historicalTrack')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'flightTraining', imei: data.title }">{{$t('welcome.page.left.flightTraining')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'modifyConfig', imei: data.title }">{{$t('devicePage.config')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'circularFence', imei: data.title }">{{$t('welcome.page.left.circularFence')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'polygonalFence', imei: data.title }">{{$t('welcome.page.left.polygonalFence')}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    </span>
                  </el-col>
                  <el-col :span="2">
                    <div v-if="data.deviceImei !== undefined" :class="getColor(data)"></div>
                  </el-col>
                  <el-col :span="10" >
                    <span style="text-align: left; "> {{ node.label }}</span>
                  </el-col>
              </span>
              </el-tree>
            </el-tab-pane>
            <el-tab-pane :label="$t('device.lost')" name="lost">
              <el-tree
                ref="mLostTree"
                :data="offlineMenuList"
                node-key="key"
                :accordion="false"
                :check-on-click-node="false"
                default-expand-all
                :show-checkbox="true"
                @check="selectNode"
                @node-click="clickNode">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <el-col :span="2">
                    <span class="itemClass">
                      <img class="treeIcon" :src="data.type === 1 ? dealerIcon : deviceIcon" alt="">
                      {{data.title}}
                      <el-link type="primary" icon="el-icon-edit" class="el-dropdown-link operation" :underline="false" v-if="data.type === 1" @click="toSoldDeviceManage(data)">{{$t('devicePage.option.edit')}}</el-link>
                      <!-- 下拉操作列表 -->
                      <div v-if="data.type === 2">
                      <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link operation">
                          {{$t('welcome.page.left.operation')}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="{ cmd: 'realTimeTracking', imei: data.title }">{{$t('welcome.page.left.realTimeTracking')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'historicalTrack', imei: data.title }">{{$t('welcome.page.left.historicalTrack')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'flightTraining', imei: data.title }">{{$t('welcome.page.left.flightTraining')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'modifyConfig', imei: data.title }">{{$t('devicePage.config')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'circularFence', imei: data.title }">{{$t('welcome.page.left.circularFence')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'polygonalFence', imei: data.title }">{{$t('welcome.page.left.polygonalFence')}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    </span>
                  </el-col>
                  <el-col :span="2">
                    <div v-if="data.deviceImei !== undefined" :class="getColor(data)"></div>
                  </el-col>
                  <el-col :span="10" >
                    <span style="text-align: left; "> {{ node.label }}</span>
                  </el-col>
              </span>
              </el-tree>
            </el-tab-pane>
            <!-- <el-tab-pane :label="$t('welcome.page.left.labelOnline')" name="online">
              <el-tree :data="onlineMenuList" :accordion="true" :check-on-click-node="true" @node-click="clickNode" default-expand-all>
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <el-col :span="2">
                    <div v-if="data.deviceImei !== undefined" :class="getColor(data)"></div>
                  </el-col>
                  <el-col :span="10" >
                    <span style="text-align: left; "> {{ node.label }}</span>
                  </el-col>
                  <el-col :span="2" :offset="7">
                    <div v-if="data.deviceImei !== undefined">
                      <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link operation">
                          {{$t('welcome.page.left.operation')}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="{ cmd: 'realTimeTracking', imei: data.title }">{{$t('welcome.page.left.realTimeTracking')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'historicalTrack', imei: data.title }">{{$t('welcome.page.left.historicalTrack')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'circularFence', imei: data.title }">{{$t('welcome.page.left.circularFence')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'polygonalFence', imei: data.title }">{{$t('welcome.page.left.polygonalFence')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'sendCommand', imei: data.title }">{{$t('welcome.page.left.sendCommand')}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </el-col>
              </span>
              </el-tree>
            </el-tab-pane> -->
            <!-- <el-tab-pane :label="$t('welcome.page.left.labelOffline')" name="offline">
              <el-tree :data="offlineMenuList" :accordion="true" :check-on-click-node="true" @node-click="clickNode" >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <el-col :span="2">
                    <span>{{data.deviceImei}}</span>
                  </el-col>
                  <el-col :span="2">
                    <div v-if="data.deviceImei !== undefined" :class="getColor(data)"></div>
                  </el-col>
                  <el-col :span="10" >
                    <span style="text-align: left; "> {{ node.label }}</span>
                  </el-col>
                  <el-col :span="2" :offset="7">
                    <div v-if="data.deviceImei !== undefined">
                      <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link operation">
                          {{$t('welcome.page.left.operation')}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="{ cmd: 'realTimeTracking', imei: data.title }">{{$t('welcome.page.left.realTimeTracking')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'historicalTrack', imei: data.title }">{{$t('welcome.page.left.historicalTrack')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'circularFence', imei: data.title }">{{$t('welcome.page.left.circularFence')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'polygonalFence', imei: data.title }">{{$t('welcome.page.left.polygonalFence')}}</el-dropdown-item>
                          <el-dropdown-item :command="{ cmd: 'sendCommand', imei: data.title }">{{$t('welcome.page.left.sendCommand')}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </el-col>
              </span>
              </el-tree>
            </el-tab-pane> -->
          </el-tabs>
        </div>
      </div>
      <div class="collapseImg" @click="collapse" :class="hasCollapse?'collapseList':''">
        <i v-if="!hasCollapse" class="el-icon-arrow-left"></i>
        <i v-else class="el-icon-arrow-right"></i>
      </div>
      <div class="mapContainer" :class="hasCollapse?'collapseList':''">
        <GmapMap
          ref="mapRef"
          v-if="currentMap === 'google'"
          map-type-id="terrain"
          :center="{lat:25.037798, lng:121.565170}"
          :zoom="15"
          style="width: 100%; height: 100%"
          :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUI: false
          }"
        >
        </GmapMap>
        <baidu-map v-if="currentMap === 'baidu'" class="baidu-map" :center="center" :zoom="15" @ready="handleBaidu"
                   :scroll-wheel-zoom='true'>
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"/>
          <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"/>
        </baidu-map>
      </div>
      <div class="deviceInfoList" v-if="showDeviceInfoList" draggable="true"
      @dragend="deviceInfoListDragEnd($event)"
      @dragstart="deviceInfoListDragStart($event)"
      :style="{position: 'absolute', right: elRight + 'px', bottom: elBottom + 'px'}">
          <span class="closeDeviceInfoListButton" type="text" @click="hideDeviceInfoList">-</span>
        <el-table
          :highlight-current-row="true"
          :data="deviceList"
          style="width: 100%;"
          max-height="200px"
          @current-change="handleCurrentChange">
  <!--        <el-table-column prop="organization" :label="$t('device.organization')" width="140px;"></el-table-column>
          <el-table-column prop="deviceName" :label="$t('device.deviceName')" width="105px;"></el-table-column> -->
          <el-table-column prop="deviceImei" :label="$t('device.deviceImei')" width="130px;"></el-table-column>
          <el-table-column prop="lastLongitude" :label="$t('device.lastLongitude')" width="100px;"></el-table-column>
          <el-table-column prop="lastLatitude" :label="$t('device.lastLatitude')" width="90px;"></el-table-column>
          <el-table-column prop="lastLocationTime" :label="$t('device.lastLocationTime')" width="160px;">
            <template slot-scope="scope">
               {{ convertDateTimeToString(scope.row.lastLocationTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="lastSpeed" :label="$t('device.lastSpeed')" width="80px;"></el-table-column>
          <el-table-column :label="$t('device.displayedStatus')" width="100px;">
            <template slot-scope="scope">
               {{ getStatus(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column prop="alarm" :label="$t('device.alarm')" width="100px;"></el-table-column>
  <!--        <el-table-column prop="lastDeviceVol" :label="$t('device.lastDeviceVol')"></el-table-column>-->
        </el-table>
      </div>
      <div class="listInfoTipBtn" v-else @click="hideDeviceInfoList">{{$t('welcome.page.left.detailList')}}</div>
      <div class="timeClass">{{$t('welcome.page.main.refreshPre') + second + $t('welcome.page.main.refreshNext')}}</div>
      <el-dialog :title="$t('welcome.page.sendCommand.title')" :visible.sync="commandDialogVisible" width="70%" @close="commandDialogClosed">
        <el-form
          :model="commandForm"
          ref="commandForm"
          :inline="true"
          :rules="commandFormRules"
          label-width="140px"
          label-position="right"
          class="commandFormClass">
          <el-row>
            <el-col :span="2">
              <el-button type="danger" @click="sendCommand(2)">{{$t('welcome.page.sendCommand.stop')}}</el-button>
            </el-col>
            <el-col :span="3">
              <el-button type="primary" @click="sendCommand(3)">{{$t('welcome.page.sendCommand.resume')}}</el-button>
            </el-col>
            <el-col :span="4">
              <el-button type="warning" @click="sendCommand(4)">{{$t('welcome.page.sendCommand.openDoor')}}</el-button>
            </el-col>
            <el-col :span="4">
              <el-button type="success" @click="sendCommand(5)">{{$t('welcome.page.sendCommand.closeDoor')}}</el-button>
            </el-col>
            <el-col :span="2">
              <el-button type="success" @click="sendCommand(1)">{{$t('welcome.page.sendCommand.start')}}</el-button>
            </el-col>
            <el-col :span="4">
              <el-button type="danger" @click="sendCommand(6)">{{$t('welcome.page.sendCommand.stopAfter')}}</el-button>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" @click="sendCommand(7)">{{$t('welcome.page.sendCommand.photograph')}}</el-button>
            </el-col>
          </el-row>
          <br/>
          <el-row>
            <el-col :span="8">
              <el-form-item :label="$t('welcome.page.sendCommand.timeInterval')" label-width="160px">
                <el-input v-model="commandForm.setTimeInterval" :placeholder="$t('welcome.page.sendCommand.timeIntervalPlaceholder')"></el-input>
                <el-button class="sendBtn" @click="setTimeIntervalForTrackingFunc"></el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('welcome.page.sendCommand.timeZone')" label-width="130px">
                <el-input class="wideInput" v-model="commandForm.timeZoneValue" :placeholder="$t('welcome.page.sendCommand.timeZonePlaceholder')"></el-input>
                <el-button class="sendBtn" @click="setTimeZoneFunc"></el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('welcome.page.sendCommand.setAngle')" label-width="160px">
                <el-input v-model="commandForm.angle" :placeholder="$t('welcome.page.sendCommand.setAnglePlaceholder')"></el-input>
                <el-button class="sendBtn" @click="setAngleForTrackingFunc"></el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item :label="$t('welcome.page.sendCommand.setDistance')" label-width="160px">
                <el-input v-model="commandForm.distance" :placeholder="$t('welcome.page.sendCommand.setDistancePlaceholder')"></el-input>
                <el-button class="sendBtn" @click="setOdometerIntervalFunc"></el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('welcome.page.sendCommand.setWiretapping')" label-width="130px">
                <el-input class="wideInput" v-model="commandForm.phoneNumber" :placeholder="$t('welcome.page.sendCommand.setWiretappingPlaceholder')"></el-input>
                <el-button class="sendBtn" @click="setTelePhoneForWiretappingFunc"></el-button>
              </el-form-item>
            </el-col>
  
            <el-col :span="8">
              <el-form-item :label="$t('welcome.page.sendCommand.setSpeed')" label-width="160px">
                <el-select v-model="commandForm.speed"
                           size="small" :placeholder="$t('welcome.page.sendCommand.setSpeedPlaceholder')">
                  <el-option v-for="dic in speedLimitDic" :key="dic.codeValue" :value="dic.codeValue"
                             :label="dic.codeName"> {{ dic.codeName }}
                  </el-option>
                </el-select>
                <el-button class="sendBtn" @click="setSpeedLimitFunc"></el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item :label="$t('welcome.page.sendCommand.movementAlert')" label-width="160px">
                <el-select v-model="commandForm.meters"
                           size="small" :placeholder="$t('welcome.page.sendCommand.movementAlertPlaceholder')">
                  <el-option v-for="dic in movementAlertDic" :key="dic.codeValue" :value="dic.codeValue"
                             :label="dic.codeName"> {{ dic.codeName }}
                  </el-option>
                </el-select>
                <el-button class="sendBtn" @click="setMovementAlertFunc"></el-button>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item :label="$t('welcome.page.sendCommand.authorizedPhone')" label-width="130px">
                <el-select v-model="commandForm.buttonNumber"
                           size="small" :placeholder="$t('welcome.page.sendCommand.authorizedPhonePlaceholder')">
                  <el-option v-for="dic in authorizedPhoneTypeDic" :key="dic.codeValue" :value="dic.codeValue"
                             :label="dic.codeName"> {{ dic.codeName }}
                  </el-option>
                </el-select>
                <el-input class="wideInput" v-model="commandForm.phoneNumberForReceiveSms"
                          :placeholder="$t('welcome.page.sendCommand.phoneSMSPlaceholder')"></el-input>
                <el-input class="wideInput" v-model="commandForm.phoneNumberForReceiveCall"
                          :placeholder="$t('welcome.page.sendCommand.phoneCALLPlaceholder')"></el-input>
                <el-button class="sendBtn" @click="setAuthorizedPhoneNumberFunc"></el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item :label="$t('welcome.page.sendCommand.setRFID')" label-width="160px">
              <el-input v-model="commandForm.setRfid1" placeholder="RFID 1"></el-input>
              <el-input v-model="commandForm.setRfid2" placeholder="RFID 2"></el-input>
              <el-input v-model="commandForm.setRfid3" placeholder="RFID 3"></el-input>
              <el-input v-model="commandForm.setRfid4" placeholder="RFID 4"></el-input>
              <el-input v-model="commandForm.setRfid5" placeholder="RFID 5"></el-input>
              <el-button class="sendBtn"></el-button>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="$t('welcome.page.sendCommand.sendMessage')" label-width="180px">
                <el-input class="sendMessage" type="textarea" v-model="commandForm.sendMessage"></el-input>
                <el-button class="sendBtn"></el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <!--        </el-card>-->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="commandDialogVisible = false">{{ $t('welcome.page.sendCommand.closeBtn') }}</el-button>
        </span>
      </el-dialog>
      <el-dialog :title="$t('welcome.page.deviceInfo.title')" :visible.sync="infoDialogVisible" width="50%" @close="infoDialogClosed">
        <el-form
          width="60%"
          :model="infoForm"
          :inline="true"
          ref="infoForm"
          max-height="100"
          label-position="left"
          class="infoFormClass">
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('device.deviceImei') + ':'">
                <span>{{ infoForm.deviceImei }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="'ICCID:'">{{ infoForm.iccid || '' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('device.onlineStatus') + ':'">{{ infoForm.onlineStatus ? 'online' : 'offline' }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('device.deviceType') + ':'">{{ getSpecifiedDeviceType(infoForm.deviceType) }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('device.createTime') + ':'">{{ infoForm.createTime}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('device.version') + ':'">{{ infoForm.version }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('device.lastDeviceVol') + ':'">{{ infoForm.lastDeviceVol || 0 }}&#37;
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('device.lastLocationTime') + ':'">{{ infoForm.lastLocationTime }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('device.lastLongitude') + ':'">{{ infoForm.lastLongitude }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('device.lastLatitude') + ':'">{{ infoForm.lastLatitude }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('device.lastSpeed') + ':'">{{ infoForm.lastSpeed }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('device.lastLocationType') + ':'">{{ getSpecifiedLocationType(infoForm.lastLocationType) }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('device.altitude') + ':'">{{infoForm.altitude}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('device.deviceRemark') + ':'">{{ infoForm.deviceRemark }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
  <!--      <span slot="footer" class="dialog-footer">
          <el-button @click="infoDialogVisible = false">Close</el-button>
        </span>-->
        <el-dialog :visible.sync="showConfigDialog" :title="($t('devicePage.configuration') + '(' + currentDeviceImei + ')')">
          <el-form :data="configurationData">
          </el-form>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.standbyDatetime')}}:&nbsp;</el-col>
            <el-col :span="6">{{getConfValueByKey('standbyDatetime')}}</el-col>
            <el-col :span="6">{{$t('config.cst')}}:&nbsp;</el-col>
            <el-col :span="6">{{getConfValueByKey('cst')}}</el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.rsut')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="datetime-local" name="rsut" @input="timeInputHandler" style="width: 95%" :value="getConfValueByKey('rsut')"/>
            </el-col>
            <el-col :span="6">{{$t('config.rgli')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.rgli"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.rdui')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.rdui"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
            <el-col :span="6">{{$t('config.lpl')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.lpl"/>
              <span>&#37;</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.lpgli')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.lpgli"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
            <el-col :span="6">{{$t('config.lpdui')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.lpdui"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.nmst')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="time" name="nmst" @input="timeInputHandler" style="width: 95%" :value="getConfValueByKey('nmst')"/>
            </el-col>
            <el-col :span="6">{{$t('config.nmet')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="time" name="nmet" @input="timeInputHandler" style="width: 95%" :value="getConfValueByKey('nmet')"/>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.ngli')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.ngli"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
            <el-col :span="6">{{$t('config.ndui')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.ndui"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.cgli')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.cgli"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
            <el-col :span="6">{{$t('config.cdui')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" v-model="configurationData.cdui"/>
              <span>{{$t('unit.minutePerTime')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.rdut')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="date" name="rdut" @input="timeInputHandler" :value="getConfValueByKey('rdut')" style="width: 95%"/>
            </el-col>
            <el-col :span="6">{{$t('config.gddt')}}:&nbsp;</el-col>
            <el-col :span="6">{{getConfValueByKey('gddt')}}</el-col>
          </el-row>
          <el-row type="flex" justify="start" :align="'middle'">
            <el-col :span="6">{{$t('config.rsud')}}:&nbsp;</el-col>
            <el-col :span="6">
              <input type="number" @input="inputDelayTime" name="rsud" :value="getConfValueByKey('rsud')">
              <span>{{this.$t('unit.minute')}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="end">
              <input type="button" @click="clickConfirmUpdateConfigurationButton(currentDeviceImei, configurationData)" :value="$t('common.confirm')">
          </el-row>
        </el-dialog>
    </div>
  </template>
  
  <script>
  import moment from 'moment'
  import {
    queryAllDealers,
    listAllGpsDevice,
    getGpsDeviceByImei,
    lastDevicePosition,
    getDeviceConfiguration,
    updateDeviceConfiguration,
    configQueryAllDealers
  } from '@/api/gpsDevice'
  import { checkLogin } from '@/api/globalProcessErr'
  import { listAllGpsUser } from '@/api/gpsUser'
  import { listCodeByClass } from '@/api/code'
  import {
    sendRemoteControl,
    setAuthorizedPhoneNumber,
    setTimeIntervalForTracking,
    setTimeZone,
    setAngleForTracking,
    setOdometerInterval,
    setTelePhoneForWiretapping,
    setSpeedLimit,
    setMovementAlert
  } from '@/api/gpsDeviceCommand'
  import { getDeviceHistoryTrajectoryLog } from '@/api/gpsDeviceTraceLog'
  import {
    batchMakeMarker,
    initMap,
    openInfoWindowByDeviceImei,
    closeCurrentInfoWindow,
    makeTrackMarker,
    makePath,
    convertTrackToDevice,
    changeMarkerPositionAndUpdateInfoWindow
    // , moveToCenter
  } from '@/api/map'
  import { deviceStatusFormat, getObjString, convertDateTimeToString } from '@/api/util'
  import { getDeviceTypeStr, getLocationTypeStr } from '../../api/enum'
  const REQUEST_ROWS = 50
  
  export default {
    name: 'locationMonitoring',
    data () {
      return {
        dealerIcon: require('@/assets/dealer.png'),
        deviceIcon: require('@/assets/device.png'),
        commandDialogVisible: false,
        commandForm: {
          deviceImei: '',
          commandBtn: undefined,
          setTimeInterval: undefined,
          timeZoneValue: undefined,
          angle: undefined,
          distance: undefined,
          phoneNumber: '',
          speed: undefined,
          meters: undefined,
          buttonNumber: undefined,
          phoneNumberForReceiveSms: '',
          phoneNumberForReceiveCall: ''
        },
        funValue: '',
        infoForm: {
          deviceImei: '',
          deviceName: '',
          deviceType: undefined,
          bindMobile: '',
          version: '',
          deviceShortNumber: '',
          deviceFamilyNumber: '',
          deviceRemark: '',
          lastLongitude: undefined,
          lastLatitude: undefined,
          lastLocationTime: undefined,
          lastLocationType: undefined,
          lastSpeed: undefined,
          lastMotionStatus: undefined,
          lastDeviceVol: '',
          lastDeviceSms: '',
          heading: undefined,
          odometer: undefined,
          onlineStatus: undefined
        },
        infoDialogVisible: false,
        timerInterval: 60,
        second: 15,
        userInterval: 10,
        showGroup: false,
        searchDeviceImei: '',
        activeName: 'all',
        selectedUser: undefined,
        gpsDeviceByOrgList: [],
        allMenuList: [],
        onlineMenuList: [],
        offlineMenuList: [],
        allMenuActiveName: '',
        onlineMenuActiveName: '',
        offlineMenuActiveName: '',
        allMenuSelectedDeviceImei: '',
        onlineMenuSelectedDeviceImei: '',
        offlineMenuSelectedDeviceImei: '',
        mCheckedKeys: [],
        deviceList: [],
        gpsUserList: [],
        showUserList: false,
        showDeviceInfoList: false,
        hasCollapse: false,
        mapInstance: undefined,
        center: {
          lat: 22.5711440000,
          lng: 113.8687300000
        },
        centerSet: false,
        devicePoints: [],
        deviceMarks: [],
        infoWindowList: [],
        openedDevice: undefined,
        currentDeviceImei: '',
        movementAlertDic: [],
        authorizedPhoneTypeDic: [],
        speedLimitDic: [],
        noStopList: [
          '/device/tracking',
          '/device/historicalTrack',
          '/device/circularFence',
          '/device/polygonalFence'
        ],
        intervalId: undefined,
        currentMap: 'baidu',
        selectedDevice: [],
        toBeRemove: [],
        toBeAdd: [],
        selectedDevices: [],
        initWidth: 0, // 父元素的宽-自适应值
        initHeight: 0, // 父元素的高-自适应值
        startClientX: 0, // 元素拖拽前距离浏览器的X轴位置
        startClientY: 0, // 元素拖拽前距离浏览器的Y轴位置
        elRight: 0, // 元素的左偏移量
        elBottom: 0, // 元素的右偏移量
        trackMap: new Map(),
        showConfigDialog: false,
        configurationData: {
          standbyDatetime: undefined,
          cst: undefined,
          rsut: undefined,
          rgli: undefined,
          rdui: undefined,
          lpl: undefined,
          lpgli: undefined,
          lpdui: undefined,
          nmst: undefined,
          nmet: undefined,
          ngli: undefined,
          ndui: undefined,
          cgli: undefined,
          cdui: undefined,
          rdut: undefined,
          gddt: undefined,
          rsud: undefined
        }
      }
    },
    props: {
      commandFormRules: {}
    },
    computed: {
      getStatus (data) {
        return function (data) {
          let statusText = ''
          if (data === undefined || data === null || data === '') {
            return ''
          }
          if (data.status === undefined || data.status === null) {
            return ''
          }
          if (data.status.engineStatus === 1) {
            statusText = 'Acc On'
          } else if (data.status.engineStatus === 0) {
            statusText = 'Acc Off'
          }
  
          if (data.status.doorStatus === 1) {
            statusText = statusText === '' ? 'Door Open' : statusText + '|Door Open'
          }
  
          if (data.status.headlightStatus === 1) {
            statusText = statusText === '' ? 'Headlights on' : statusText + '|Headlights on'
          }
  
          if (data.status.airConditionStatus === 1) {
            statusText = statusText === '' ? 'Air conditioner on' : statusText + '|Air conditioner on'
          }
  
          return statusText
        }
      },
      getColor (data) {
        return function (data) {
          if (data === undefined || data === null || data === '') {
            return ''
          }
          if (data.statusText === undefined || data.statusText === null || data.statusText === '') {
            return ''
          }
          if (data.onlineStatus && data.lastMotionStatus === 1) {
            return 'statusRun'
          }
          if (data.onlineStatus) {
            return 'statusOnline'
          }
          return 'statusOffline'
        }
      },
      totalDev: function () {
        return this.$t('welcome.page.left.deviceTotal') + this.deviceList.length + '  '
      },
      offlineDev: function () {
        let offline = 0
        if (this.deviceList.length > 0) {
          for (let i = 0; i < this.deviceList.length; i++) {
            if (this.deviceList[i].onlineStatus) {
              break
            }
            offline++
          }
        }
        return this.$t('welcome.page.left.deviceOffline') + offline + '  '
      },
      onlineDev: function () {
        let online = 0
        if (this.deviceList.length > 0) {
          for (let i = 0; i < this.deviceList.length; i++) {
            if (this.deviceList[i].onlineStatus && (this.deviceList[i].lastMotionStatus === null || this.deviceList[i].lastMotionStatus === 0)) {
              online++
            }
          }
        }
        return this.$t('welcome.page.left.deviceOnline') + online + '  '
      },
      runDev: function () {
        let run = 0
        if (this.deviceList.length > 0) {
          for (let i = 0; i < this.deviceList.length; i++) {
            if (this.deviceList[i].onlineStatus && this.deviceList[i].lastMotionStatus === 1) {
              run++
            }
          }
        }
        return this.$t('welcome.page.left.deviceRun') + run
      }
    },
    created () {
      const beforeUnload = function () {
        sessionStorage.removeItem('deviceImei')
        sessionStorage.removeItem('dealerId')
        sessionStorage.removeItem('dealerName')
        sessionStorage.removeItem('historicalTrackDeviceImei')
        window.removeEventListener('beforeunload', beforeUnload)
      }
      window.addEventListener('beforeunload', beforeUnload)
      this.$i18n.locale = window.sessionStorage.getItem('lang')
      this.checkLang()
      // this.listAllUser()
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.intervalId)
      next()
    },
    mounted () {
      let _this = this
      if (sessionStorage.getItem('token')) {
        // const device = JSON.parse(sessionStorage.getItem('device'))
        this.listAllDealers()
      }
      window.realTimeTrack = function (deviceImei) {
        _this.realTimeTrack(deviceImei)
      }
      window.historicalTrack = function (deviceImei) {
        _this.historicalTrack(deviceImei)
      }
      window.sendCommand = function (deviceImei) {
        _this.openSendCommandDiag(deviceImei)
      }
      window.modifyConfiguration = function (deviceImei) {
        _this.modifyConfiguration(deviceImei)
      }
      window.flightTraining = function (deviceImei) {
        _this.flightTraining(deviceImei)
      }
      window.getDeviceInfo = function (deviceImei) {
        _this.getDeviceInfo(deviceImei)
      }
      setTimeout(function () {
        _this.initMapPage()
        _this.markAllDevice()
        // moveToCenter(_this.mapInstance, _this.center)
      }, 800)
  
      // setTimeout(function () {
      //   moveToCenter(_this.mapInstance, _this.center)
      // }, 800)
  
      _this.intervalId = setInterval(function () {
        _this.interval()
      }, 1000)
      _this.initWidth = document.documentElement.clientWidth
      _this.initHeight = document.documentElement.clientHeight
    },
    methods: {
      handleCommand (data) {
        switch (data.cmd) {
          case 'realTimeTracking':
            this.realTimeTrack(data.imei)
            break
          case 'historicalTrack':
            this.historicalTrack(data.imei)
            break
          case 'circularFence':
            this.openCircularFence(data.imei)
            break
          case 'polygonalFence':
            this.openPolygonalFence(data.imei)
            break
          case 'sendCommand':
            this.openSendCommandDiag(data.imei)
            break
          case 'flightTraining':
            this.flightTraining(data.imei)
            break
          case 'modifyConfig':
            this.modifyConfiguration(data.imei)
            break
        }
      },
      getMovementAlertDic () {
        listCodeByClass('MOVEMENT_ALERT').then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg)
          }
          this.movementAlertDic = res.data.data
        }).catch(err => {
          return this.$message.error(err.message)
        })
      },
      getAuthorizedPhoneTypeDic () {
        listCodeByClass('AUTHORIZED_PHONE_BUTTON').then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg)
          }
          this.authorizedPhoneTypeDic = res.data.data
        }).catch(err => {
          return this.$message.error(err.message)
        })
      },
      getSpeedLimitDic () {
        listCodeByClass('SPEED_ALERT').then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg)
          }
          this.speedLimitDic = res.data.data
        }).catch(err => {
          return this.$message.error(err.message)
        })
      },
      checkLang () {
        if (this.$i18n.locale === 'zh-CN') {
        this.currentMap = 'baidu'
      } else {
        this.currentMap = 'google'
      }
      },
      handleBaidu ({ BMap, map }) {
        this.mapInstance = {
          mapType: 'baidu',
          map: map,
          bMap: BMap,
          // map: window.$BAIDU$._instances.TANGRAM__1,
          // bMap: window.BMap,
          markList: [],
          polyList: [],
          currentOpenInfoWindow: undefined,
          contentFunc: this.makeInfoWindowConetnt,
          haveBtn: true
        }
      },
      initMapPage () {
        if (this.currentMap === 'google') {
          if (this.mapInstance === undefined) {
            this.mapInstance = {
              mapType: 'google',
              map: this.$refs.mapRef.$mapObject,
              mapRef: this.$refs.mapRef,
              googleMaps: window.google.maps,
              markList: [],
              polyList: [],
              currentOpenInfoWindow: undefined,
              contentFunc: this.makeInfoWindowConetnt,
              haveBtn: true
            }
          }
        } else {
          if (this.mapInstance === undefined) {
            this.mapInstance = {
              mapType: 'baidu',
              // map: map,
              // bMap: BMap,
              map: window.$BAIDU$._instances.TANGRAM__1,
              bMap: window.BMap,
              markList: [],
              polyList: [],
              currentOpenInfoWindow: undefined,
              contentFunc: this.makeInfoWindowConetnt,
              haveBtn: true
            }
          }
        }
        initMap(this.mapInstance, this.center, 15)
      },
      markAllDevice () {
        // this.mapInstance = batchMakeMarker(this.mapInstance, this.selectedDevice)
        this.mapInstance = batchMakeMarker(this.mapInstance, this.deviceList)
        // this.deviceList.forEach(item => {
        //   this.mapInstance.markList.forEach(element => {
        //     if (element.deviceImei === item.deviceImei) {
        //       this.mapInstance.map.removeOverlay(element.marker)
        //     }
        //   })
        // })
      },
      makeInfoWindowConetnt (device, haveBtn) {
        const self = this
        return function () {
          let content = '<style>'
          content += '.deviceInfo { font-size: 12px; margin: 0; padding: 0;}'
          content += 'ul li { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; }'
          content += 'ul li div { width: 200px; }'
          content += '.centerDiv{ width: 100%; display: flex; justify-content: space-around; margin-top: 4px; }'
          content += '.centerDiv button { font-size: 12px; width: 60px; border-radius: 10px; color: white; }'
          content += '.buluButton { border: 1px solid #00A2E9; background-color: rgba(0, 162, 240 ,0.5); }'
          content += '.orangeButton { border: 1px solid chocolate; background-color: rgba(210, 105, 30, 0.5); } '
          content += '.redButton { border: 1px solid red; background-color: rgba(233, 10, 10, 0.5); }'
          content += '.purpleButton { border: 1px solid purple; background-color: rgba(128, 0, 128, 0.5); }'
          content += '.yellowButton { border: 1px solid #F0AC20; background-color: rgba(242, 120, 32, 0.5); }'
          content += '</style>'
          content += '<ul class="deviceInfo">'
          if (device.deviceType !== 2) {
            let item = 'engine'
            content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + device.deviceImei + '</span> </div>'
            content += '<div> <b>' + self.$t('device.deviceName') + ':</b> <span>' + device.deviceName + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + getObjString(device.lastLatitude) + '</span> </div>'
            content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + getObjString(device.lastLongitude) + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.accStatus') + ':</b> <span>' + deviceStatusFormat(device, item) + '</span> </div>'
            content += '<div> <b>' + self.$t('device.lastLocationTime') + ':</b> <span>' + convertDateTimeToString() + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.lastSpeed') + ':</b> <span>' + getObjString(device.lastSpeed) + '</span> </div>'
            content += '<div> <b>' + self.$t('device.heading') + ':</b> <span>' + getObjString(device.heading) + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.odometer') + ':</b> <span>' + getObjString(device.odometer) + '</span> </div>'
            content += '<div> <b>' + self.$t('device.fuel') + ':</b> <span>' + getObjString(device.fuel) + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.temperature') + ':</b> <span></span> </div>'
            content += '<div> <b>' + self.$t('device.address') + ':</b> <span></span> </div> </li>'
          } else {
            let speed = 0
            if (device.odometer && device.duration && device.duration !== 0) {
              speed = device.odometer / device.duration
            }
            let averageSpeed = 0
            if (device.accumulateOdometer && device.accumulateDuration && device.accumulateDuration !== 0) {
              averageSpeed = device.accumulateOdometer / device.accumulateDuration
            }
            content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + device.deviceImei + '</span> </div>'
            content += '<div> <b>' + self.$t('device.nickname') + ':</b> <span>' + device.deviceName + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.lastDeviceVol') + ':</b> <span>' + (device.lastDeviceVol || 0) + '%' + '</span> </div>'
            content += '<div> <b>' + self.$t('device.version') + ':</b> <span>' + (device.version || '') + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.positioningTime') + ':</b> <span>' + device.lastGpsTime + '</span> </div>'
            content += '<div> <b>' + self.$t('device.uploadTime') + ':</b> <span>' + device.lastLocationTime + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + device.lastLatitude + '</span> </div>'
            content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + device.lastLongitude + '</span> </div> </li>'
            // content += '<li> <div> <b>' + self.$t('device.weather') + ':</b> <span>' + device.weather + '</span> </div>'
            // content += '<div> <b>' + self.$t('device.lastPositionDesc') + ':</b> <span>' + device.lastPositionDesc + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.distanceFromPreviousPoint') + ':</b> <span>' + (device.odometer || 0) + self.$t('unit.meter') + '</span> </div>'
            content += '<div> <b>' + self.$t('device.timeFromPreviousPoint') + ':</b> <span>' + (device.duration || 0) + self.$t('unit.second') + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('unit.speedPerMinute') + ':</b> <span>' + (speed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
            content += '<div> <b>' + self.$t('unit.speedPerHour') + ':</b> <span>' + (speed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('unit.averageSpeedPerMinute') + ':</b> <span>' + (averageSpeed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
            content += '<div> <b>' + self.$t('unit.averageSpeedPerHour') + ':</b> <span>' + (averageSpeed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
            content += '<li> <div> <b>' + self.$t('device.altitude') + ':</b> <span>' + (device.altitude || 0) + self.$t('unit.meter') + '</span> </div>'
            content += '<div> <b>' + self.$t('device.locationPoints') + ':</b> <span>' + (device.pointIndex || '') + '</span> </div> </li>'
          }
          if (haveBtn) {
            content += '<li> <div class="centerDiv">'
            content += '<button class="buluButton" onclick="realTimeTrack(\'' + device.deviceImei + '\')"" type="button"> ' + self.$t('devicePage.realTimeTrack') + ' </button>'
            content += '<button class="orangeButton" onclick="historicalTrack(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.historicalTrack') + '  </button>'
            content += '<button class="redButton" onclick="flightTraining(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.flightTraining') + '  </button>'
            // content += '<button onclick="sendCommand(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.sendCommand') + '  </button>'
            content += '<button class="purpleButton" onclick="modifyConfiguration(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.config') + '  </button>'
            content += '<button class="yellowButton" onclick="getDeviceInfo(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.getDeviceInfo') + ' </button>'
            content += '</div> </li>'
          }
          content += '</ul>'
          return content
        }
      },
      interval () {
        this.second--
        if (this.second < 1) {
          const _this = this
          _this.selectedDevices.forEach(item => {
            _this.getDevicePosition(item)
          })
          this.second = this.timerInterval
        }
      },
      getDevicePosition (imei) {
        lastDevicePosition(imei).then(res => {
          if (res.data.code === 0 && res.data.data) {
            const data = res.data.data
            const lng = data.lastLongitude
            const lat = data.lastLatitude
            let trackList = []
            if (this.trackMap.has(imei)) {
              trackList = this.trackMap.get(imei).tracks
            }
            this.mapInstance.markList.forEach(item => {
              if (item.deviceImei === imei) {
                const point = item.marker.getPosition()
                if ((point.lat !== lat || point.lng !== lng) && (lat !== 0 || lng !== 0)) {
                  // changeMarkerPositionAndUpdateInfoWindow(this.mapInstance, res.data.data)
                  if (trackList.length > 0) {
                    this.getDeviceHistory(imei, trackList[trackList.length - 1].logId)
                  } else {
                    this.getDeviceHistory(imei, 0)
                  }
                }
                return null
              }
            })
          }
        })
      },
      getDeviceHistory (imei, logId) { // 获取设备的历史轨迹
        const data = {
          deviceImei: imei,
          startTime: moment(new Date()).format('yyyy-MM-DD 00:00:00'),
          endTime: moment(new Date()).format('yyyy-MM-DD 23:59:59'),
          nextId: logId,
          requestRows: REQUEST_ROWS
        }
        getDeviceHistoryTrajectoryLog(data).then(res => {
          if (res.data.code === 0 && res.data.data && res.data.data.list && res.data.data.list.length > 0) {
            if (this.trackMap.has(imei)) { // 将trackLog对象存放到Map<imei, trackList>中
              const list = this.trackMap.get(imei).tracks
              res.data.data.list.forEach(item => {
                if (list.length === 0) {
                  makeTrackMarker(this.mapInstance, item, 'start')
                } else {
                  if (item.supplement === 1) {
                    makeTrackMarker(this.mapInstance, item, 'supplement')
                  } else {
                    makeTrackMarker(this.mapInstance, item, 'subordinate')
                  }
                  if (list.length === res.data.data.list.length - 1) {
                    this.mapInstance.markList.forEach(element => {
                      if (element.deviceImei === imei && element.lastLatitude !== 0 && element.lastLongitude !== 0) {
                        changeMarkerPositionAndUpdateInfoWindow(this.mapInstance, convertTrackToDevice(item))
                        return null
                      }
                    })
                  }
                }
                list.push(item)
              })
              makePath(this.mapInstance, list)
            }
            if (res.data.data.list.length === REQUEST_ROWS && res.data.data.list[REQUEST_ROWS - 1].logId) {
              this.getDeviceHistory(imei, res.data.data.list[REQUEST_ROWS - 1].logId)
            }
          }
        })
      },
      getDeviceByDeviceImei (deviceImei) {
        getGpsDeviceByImei(deviceImei).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg)
          }
          this.infoForm = res.data.data
        })
      },
      searchHandle () {
        openInfoWindowByDeviceImei(this.mapInstance, this.searchDeviceImei)
      },
      clickNode (node, data, value) { // 点击节点
        if (data.data.title && data.childNodes.length === 0) {
          openInfoWindowByDeviceImei(this.mapInstance, data.data.title)
        }
      },
      selectNode (data, node) { // 选择节点
        this.selectedDevices = []
        node.checkedNodes.forEach(item => {
          if (item.type === 2) {
            this.selectedDevices.push(item.title)
          }
        })
      },
      getToBeAdd (node) {
        this.toBeAdd = []
        if (node.checkedNodes.length === 0) {
          return
        }
        for (let k = 0; k < node.checkedNodes.length; k++) {
          if (node.checkedNodes[k].title !== undefined) {
            this.toBeAdd.push(node.checkedNodes[k].title)
          }
        }
      },
      getChildDevice (data) {
        if (data.title === undefined) {
          if (data.children.length === 0) {
            return
          }
          for (let i = 0; i < data.children.length; i++) {
            this.getChildDevice(data.children[i], this.toBeRemove)
          }
        } else {
          this.toBeRemove.push(data.title)
        }
      },
      processNodeArray (deviceImei, selected) {
        if (deviceImei) {
          let d = this.queryDevice(this.deviceList, deviceImei)
          let j = this.queryDevice(this.selectedDevice, deviceImei)
          if (selected) {
            if (j !== undefined) {
              return
            }
            this.selectedDevice.push(this.deviceList[d])
          } else {
            if (j !== undefined) {
              this.selectedDevice.splice(j, 1)
            }
          }
        }
      },
      queryDevice (list, deviceImei) {
        if (list.length !== 0) {
          for (let i = 0; i < list.length; i++) {
            if (deviceImei === list[i].deviceImei) {
              return i
            }
          }
        }
      },
      listAllUser () {
        listAllGpsUser().then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg)
          }
          this.gpsUserList = res.data.data
        }).catch(err => {
          return this.$message.error(err.message)
        })
      },
      listAllDevice () { // 获取该账号下的所有设备
        const _this = this
        listAllGpsDevice().then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return _this.$message.error(res.data.msg)
          }
          res.data.data.forEach(item => {
            _this.deviceList.push(item)
          })
          if (_this.deviceList.length === 0) {
            return
          }
          for (let i = 0; i < _this.deviceList.length; i++) {
            if (_this.deviceList[i].lastLatitude !== null && _this.deviceList[i].lastLongitude !== null) {
              _this.center.lat = _this.deviceList[i].lastLatitude
              _this.center.lng = _this.deviceList[i].lastLongitude
              break
            }
          }
        }).catch(err => {
          return this.$message.error(err.message)
        })
      },
      listAllDealers () {
        const userId = sessionStorage.getItem('userId') || 0 // 当前帐号ID
        if (userId === 0) return
        const _this = this
        configQueryAllDealers(userId).then(res => {
          if (res.code === 0) {
            const tempArray = res.data[0]
          
            let node, offlineNode, onlineNode
            let onlineDevice = ''
                this.devices = []
                res.data[0].forEach(item => {
                  this.devices.push(item)
                  this.device = item
                  onlineDevice = item.deviceImei
                })
                this.devices.forEach((item, index) => {
              // if (item.gpsDeviceList) { // 将所有经销商下的所有设备添加到设备列表
              //   item.gpsDeviceList.forEach(element => {
                  _this.deviceList.push(element)
                  _this.selectedDevices.push(element.deviceImei)
                  _this.getDeviceHistory(element.deviceImei, 0)
                  _this.trackMap.set(element.deviceImei, { tracks: [] }) // 预设设备的历史轨迹map
              //   })
              // }
              if (item.userId.toString() === sessionStorage.getItem('userId')) { // 当前账号下的设备列表
                node = {
                  title: item.userName,
                  key: '0',
                  nodeId: item.userId,
                  depth: 1,
                  type: 1,
                  children: []
                }
                offlineNode = {
                  title: item.userName,
                  key: '0',
                  nodeId: item.userId,
                  depth: 1,
                  type: 1,
                  children: []
                }
                onlineNode = {
                  title: item.userName,
                  key: '0',
                  nodeId: item.userId,
                  depth: 1,
                  type: 1,
                  children: []
                }
                if (item.gpsDeviceList) {
                  item.gpsDeviceList.forEach(element => {
                    node.children.push({
                      title: element.deviceImei,
                      key: node.key + '-' + node.children.length,
                      nodeId: element.deviceId,
                      depth: 2,
                      type: 2
                    })
                    if (element.deviceStatus === 1) {
                      onlineNode.children.push({
                        title: element.deviceImei,
                        key: onlineNode.key + '-' + onlineNode.children.length,
                        nodeId: element.deviceId,
                        depth: 2,
                        type: 2
                      })
                    } else {
                      offlineNode.children.push({
                        title: element.deviceImei,
                        key: offlineNode.key + '-' + offlineNode.children.length,
                        nodeId: element.deviceId,
                        depth: 2,
                        type: 2
                      })
                    }
                  })
                }
                _this.allMenuList.push(node)
                _this.offlineMenuList.push(offlineNode)
                _this.onlineMenuList.push(onlineNode)
                tempArray.splice(index, 1)
              }
            })
            _this.markAllDevice()
            this.recursion(tempArray, node, offlineNode, onlineNode)
            _this.$nextTick(() => {
              _this.$refs.mTree.setCheckedKeys(['0'])
            })
          }
        })
      },
      recursion (dataArray, node, offlineNode, onlineNode) { // 整理经销商与设备的层级树
        if (dataArray.length === 0) return
        for (let i = 0; i < dataArray.length - 1; i++) {
          if (dataArray[i].parentId === node.nodeId) {
            const currentNode = {
              title: dataArray[i].userName,
              key: node.key + '-' + node.children.length,
              nodeId: dataArray[i].userId,
              depth: node.depth + 1,
              type: 1,
              children: []
            }
            const currentOfflineNode = {
              title: dataArray[i].userName,
              key: offlineNode.key + '-' + offlineNode.children.length,
              nodeId: dataArray[i].userId,
              depth: offlineNode.depth + 1,
              type: 1,
              children: []
            }
            const currentOnlineNode = {
              title: dataArray[i].userName,
              key: onlineNode.key + '-' + onlineNode.children.length,
              nodeId: dataArray[i].userId,
              depth: onlineNode.depth + 1,
              type: 1,
              children: []
            }
            if (dataArray[i].gpsDeviceList) {
              dataArray[i].gpsDeviceList.forEach(element => {
                currentNode.children.push({
                  title: element.deviceImei,
                  key: currentNode.key + '-' + currentNode.children.length,
                  nodeId: element.deviceId,
                  depth: node.depth + 1,
                  type: 2
                })
                if (element.deviceStatus === 1) {
                  currentOnlineNode.children.push({
                    title: element.deviceImei,
                    key: currentOnlineNode.key + '-' + currentOnlineNode.children.length,
                    nodeId: element.deviceId,
                    depth: onlineNode.depth + 1,
                    type: 2
                  })
                } else {
                  currentOfflineNode.children.push({
                    title: element.deviceImei,
                    key: currentOfflineNode.key + '-' + currentOfflineNode.children.length,
                    nodeId: element.deviceId,
                    depth: offlineNode.depth + 1,
                    type: 2
                  })
                }
              })
            }
            node.children.push(currentNode)
            offlineNode.children.push(currentOfflineNode)
            onlineNode.children.push(currentOnlineNode)
            dataArray.splice(i, 1)
            i = i - 1
            if (dataArray.length > 0 && node.children) {
              node.children.forEach(item => {
                if (item.type === 1) {
                  let node1, node2
                  for (let i = 0; i < offlineNode.children.length; i++) {
                    const element = offlineNode.children[i]
                    if (element.type === 1 && element.title === item.title) {
                      node1 = element
                    }
                  }
                  for (let i = 0; i < onlineNode.children.length; i++) {
                    const element = onlineNode.children[i]
                    if (element.type === 1 && element.title === item.title) {
                      node2 = element
                    }
                  }
                  this.recursion(dataArray, item, node1, node2)
                }
              })
            }
          }
        }
      },
      getDeviceConfigurationByImei (imei) {
        getDeviceConfiguration(imei).then(res => {
          if (res.data.code === 0) {
            if (res.data.data && res.data.data[0] && res.data.data[0][0]) {
              this.configurationData = res.data.data[0][0]
            }
          }
        })
      },
      collapse () {
        this.hasCollapse = !this.hasCollapse
      },
      handleCurrentChange (val, oldRow) {
        openInfoWindowByDeviceImei(this.mapInstance, val.deviceImei)
      },
      handleCurrentUser (val) {
        this.selectedUser = val
        return this.$message.info(this.selectedUser.userName)
      },
      deviceTypeFormatter (row, column) {
        if (row.deviceType === 1) {
          return 'Positioner'
        } else {
          return 'Watch'
        }
      },
      motionStatusFormatter (row, column) {
        if (row.lastMotionStatus === 1) {
          return 'motion'
        } else if (row.lastMotionStatus === 0) {
          return 'static'
        } else {
          return ''
        }
      },
      locationTypeFormatter (row, column) {
        if (row.lastLocationType === 1) {
          return 'WIFI'
        } else if (row.lastLocationType === 0) {
          return 'GPS'
        } else {
          return 'Base Station'
        }
      },
      realTimeTrack (deviceImei) {
        closeCurrentInfoWindow(this.mapInstance, deviceImei)
        let routeData = this.$router.resolve({
          path: '/device/tracking'
        })
        window.sessionStorage.setItem('trackingDeviceImei', deviceImei)
        window.open(routeData.href, '')
      },
      handleAllSelect (key, keyPath) {
        if (key === 'historical') {
          return this.historicalTrack(this.allMenuSelectedDeviceImei)
        }
        if (key === 'circularFence') {
          return this.openCircularFence(this.allMenuSelectedDeviceImei)
        }
        if (key === 'polygonalFence') {
          return this.openPolygonalFence(this.allMenuSelectedDeviceImei)
        }
        if (key === 'sendCommand') {
          return this.openSendCommandDiag(this.allMenuSelectedDeviceImei)
        }
        if (key === 'config') {
          return this.openConfigDialog(this.allMenuSelectedDeviceImei)
        }
      },
      handleOnlineSelect (key, keyPath) {
        if (key === 'historical') {
          return this.historicalTrack(this.onlineMenuSelectedDeviceImei)
        }
        if (key === 'circularFence') {
          return this.openCircularFence(this.onlineMenuSelectedDeviceImei)
        }
        if (key === 'polygonalFence') {
          return this.openPolygonalFence(this.onlineMenuSelectedDeviceImei)
        }
        if (key === 'sendCommand') {
          return this.openSendCommandDiag(this.onlineMenuSelectedDeviceImei)
        }
        if (key === 'config') {
          return this.openConfigDialog(this.allMenuSelectedDeviceImei)
        }
      },
      handleOfflineSelect (key, keyPath) {
        if (key === 'historical') {
          return this.historicalTrack(this.offlineMenuSelectedDeviceImei)
        }
        if (key === 'circularFence') {
          return this.openCircularFence(this.offlineMenuSelectedDeviceImei)
        }
        if (key === 'polygonalFence') {
          return this.openPolygonalFence(this.offlineMenuSelectedDeviceImei)
        }
        if (key === 'sendCommand') {
          return this.openSendCommandDiag(this.offlineMenuSelectedDeviceImei)
        }
        if (key === 'config') {
          return this.openConfigDialog(this.allMenuSelectedDeviceImei)
        }
      },
      historicalTrack (deviceImei) {
        closeCurrentInfoWindow(this.mapInstance, deviceImei)
        let routeData = this.$router.resolve({
          path: '/device/historicalTrack'
        })
        window.sessionStorage.setItem('historicalTrackDeviceImei', deviceImei)
        window.open(routeData.href, '')
      },
      openCircularFence (deviceImei) {
        closeCurrentInfoWindow(this.mapInstance, deviceImei)
        let routeData = this.$router.resolve({
          path: '/device/circularFence'
        })
        window.sessionStorage.setItem('circularFenceDeviceImei', deviceImei)
        window.open(routeData.href, '')
      },
      openPolygonalFence (deviceImei) {
        closeCurrentInfoWindow(this.mapInstance, deviceImei)
        let routeData = this.$router.resolve({
          path: '/device/polygonalFence'
        })
        window.sessionStorage.setItem('polygonalFenceDeviceImei', deviceImei)
        window.open(routeData.href, '')
      },
      getDeviceInfo (deviceImei) {
        this.getDeviceByDeviceImei(deviceImei)
        this.infoDialogVisible = true
      },
      openSendCommandDiag (deviceImei) {
        this.getMovementAlertDic()
        this.getAuthorizedPhoneTypeDic()
        this.getSpeedLimitDic()
        this.currentDeviceImei = deviceImei
        const self = this
        setTimeout(function () {
          self.commandDialogVisible = true
        }, 300)
      },
      sendCommand (cmdValue) {
        const req = {
          deviceImei: this.currentDeviceImei,
          cmdValue: cmdValue
        }
        const self = this
        sendRemoteControl(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          self.$message.info('Send Command Success!')
        }).catch(err => {
          return self.$message.error(err.message)
        })
      },
      modifyConfiguration (deviceImei) {
        // closeCurrentInfoWindow(this.mapInstance, deviceImei)
        window.sessionStorage.setItem('deviceImei', deviceImei)
        this.showConfigDialog = true
        this.currentDeviceImei = deviceImei
        this.configurationData = {}
        this.getDeviceConfigurationByImei(deviceImei)
        // window.open(this.$router.resolve({
        //   path: 'device/configurationInfo'
        // }).href, '')
      },
      flightTraining (deviceImei) {
        sessionStorage.setItem('deviceImei', deviceImei)
        window.open(this.$router.resolve({ path: 'device/flightTraining' }).href, '')
      },
      setTimeIntervalForTrackingFunc () {
        const req = {
          deviceImei: this.currentDeviceImei,
          interval: this.commandForm.setTimeInterval
        }
        setTimeIntervalForTracking(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg)
          }
          return this.$message.info('Send Command Success!')
        }).catch(err => {
          return this.$message.error(err.message)
        })
      },
      setTimeZoneFunc () {
        const req = {
          deviceImei: this.currentDeviceImei,
          timeZoneValue: this.commandForm.timeZoneValue
        }
        const self = this
        setTimeZone(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          return self.$message.info(self.$t('welcome.msg.sendCmdSuccess'))
        }).catch(err => {
          return self.$message.error(err.message)
        })
      },
      setAngleForTrackingFunc () {
        const req = {
          deviceImei: this.currentDeviceImei,
          angle: this.commandForm.angle
        }
        const self = this
        setAngleForTracking(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          return self.$message.info(self.$t('welcome.msg.sendCmdSuccess'))
        }).catch(err => {
          return self.$message.error(err.message)
        })
      },
      setOdometerIntervalFunc () {
        const req = {
          deviceImei: this.currentDeviceImei,
          distance: this.commandForm.distance
        }
        const self = this
        setOdometerInterval(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          return self.$message.info(self.$t('welcome.msg.sendCmdSuccess'))
        }).catch(err => {
          return self.$message.error(err.message)
        })
      },
      setTelePhoneForWiretappingFunc () {
        const req = {
          deviceImei: this.currentDeviceImei,
          phoneNumber: this.commandForm.phoneNumber
        }
        const self = this
        setTelePhoneForWiretapping(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          return self.$message.info(self.$t('welcome.msg.sendCmdSuccess'))
        }).catch(err => {
          return self.$message.error(err.message)
        })
      },
      setSpeedLimitFunc () {
        const req = {
          deviceImei: this.currentDeviceImei,
          speed: this.commandForm.speed
        }
        const self = this
        setSpeedLimit(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          return self.$message.info(self.$t('welcome.msg.sendCmdSuccess'))
        }).catch(err => {
          return self.$message.error(err.message)
        })
      },
      setMovementAlertFunc () {
        const req = {
          deviceImei: this.currentDeviceImei,
          meters: this.commandForm.meters
        }
        const self = this
        setMovementAlert(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          return self.$message.info(self.$t('welcome.msg.sendCmdSuccess'))
        }).catch(err => {
          return self.$message.error(err.message)
        })
      },
      setAuthorizedPhoneNumberFunc () {
        const req = {
          deviceImei: this.currentDeviceImei,
          buttonNumber: this.commandForm.buttonNumber,
          phoneNumberForReceiveSms: this.commandForm.phoneNumberForReceiveSms,
          phoneNumberForReceiveCall: this.commandForm.phoneNumberForReceiveCall
        }
        const self = this
        setAuthorizedPhoneNumber(req).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          return self.$message.info(self.$t('welcome.msg.sendCmdSuccess'))
        }).catch(err => {
          return self.$message.error(err.message)
        })
      },
      handleChange (val) {
      },
      handleNodeClick (data) {
      },
      handleClick () {
      },
      hideUserList () {
        this.showUserList = !this.showUserList
      },
      hideDeviceInfoList () {
        this.showDeviceInfoList = !this.showDeviceInfoList
      },
      commandDialogClosed () {
        this.currentDeviceImei = ''
        this.$refs.commandForm.resetFields()
      },
      infoDialogClosed () {
        this.infoForm = {
          deviceImei: '',
          deviceName: '',
          deviceType: undefined,
          bindMobile: '',
          version: '',
          deviceShortNumber: '',
          deviceFamilyNumber: '',
          deviceRemark: '',
          lastLongitude: undefined,
          lastLatitude: undefined,
          lastLocationTime: undefined,
          lastLocationType: undefined,
          lastSpeed: undefined,
          lastMotionStatus: undefined,
          lastDeviceVol: '',
          lastDeviceSms: '',
          heading: undefined,
          odometer: undefined,
          onlineStatus: undefined
        }
        this.$refs.infoForm.resetFields()
      },
      convertDateTimeToString (date) {
        return moment(date).format('yyyy/MM/DD HH:mm:ss')
      },
      deviceInfoListDragStart (e) {
        // console.log('start:' + e.clientX + '&&' + e.clientY)
        this.startClientX = e.clientX
        this.startClientY = e.clientY
      },
      deviceInfoListDragEnd (e) {
        // console.log('end:' + e.clientX + '&&' + e.clientY)
        const target = document.getElementsByClassName('deviceInfoList')[0]
        const parent = document.getElementsByClassName('el-main')[0]
        // console.log(parent.offsetHeight)
        let x = e.clientX - this.startClientX
        let y = e.clientY - this.startClientY
        // console.log('x: ' + x + '&&' + 'y:' + y)
        this.elRight -= x
        this.elBottom -= y
        const heightThreshold = parent.offsetHeight - target.offsetHeight
        const widthThreshold = parent.offsetWidth - target.offsetWidth
        if (this.elBottom >= heightThreshold) { // 对Y轴偏移量进行限制,防止完全移出屏幕
          this.elBottom = heightThreshold
        } else if (this.elBottom <= 0) {
          this.elBottom = 0
        }
        if (this.elRight >= widthThreshold) { // 对X轴偏移量进行限制,防止完全移出屏幕
          this.elRight = widthThreshold
        } else if (this.elRight <= 0) {
          this.elRight = 0
        }
        target.style.right = this.elRight + 'px'
        target.style.bottom = this.elBottom + 'px'
      },
      toSoldDeviceManage (data) {
        // this.$router.push({ path: 'device/soldDevice' })
        sessionStorage.setItem('dealerId', data.nodeId)
        sessionStorage.setItem('dealerName', data.title)
        window.open(this.$router.resolve({ path: 'device/soldDevice' }).href, '')
      },
      getConfValueByKey (key) {
        if (!this.configurationData || !this.configurationData[key]) return ''
        // console.log('configuration[' + key + ']: ' + this.configurationData[key])
        switch (key) {
          case 'standbyDatetime': // 开始待机时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DD HH:mm:ss') : ''
          case 'rsut': // 设定预约开机时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DDTHH:mm') : ''
          case 'cst': // 配置数据修改时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DD HH:mm:ss') : ''
          case 'rgli': // 定位频率
          case 'rdui': // 上传频率
            return this.configurationData[key]
          case 'lpl': // 低电量门限
            return this.configurationData[key]
          case 'lpgli': // 低电量模式定位频率
          case 'lpdui': // 低电量模式上传频率
            return this.configurationData[key]
          case 'nmst': // 第二段开始时间
          case 'nmet': // 第二段结束时间
            // console.log(moment(this.configurationData[key]).format('HH:mm'))
            return this.configurationData[key] ? moment(this.configurationData[key]).utc(0).format('HH:mm') : ''
          case 'ngli': // 第二段定位频率
          case 'ndui': // 第二段数据上传频率
          case 'cgli': // 第三段定位频率
          case 'cdui': // 第三段数据上传频率
            return this.configurationData[key]
          case 'rdut': // 数据上传启动时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DD') : ''
          case 'gddt': // 开始下载星历时间
            return this.configurationData[key] ? moment(this.configurationData[key]).format('yyyy-MM-DD HH:mm:ss') : ''
          case 'rsud': // 比赛延迟开始时间
            return this.configurationData[key] / 60 || 0
        }
      },
      timeInputHandler (e) {
        switch (e.target.name) {
          case 'nmst':
          case 'nmet': {
            const timeArray = e.target.value.split(':')
            let timeStamp = 0
            for (let i = 0; i < timeArray.length; i++) {
              const item = parseInt(timeArray[i])
              if (i === 0) {
                timeStamp += item * 1000 * 60 * 60
              } else {
                timeStamp += item * 1000 * 60
              }
            }
            this.configurationData[e.target.name] = timeStamp
          }
            break
          case 'rdut':
          case 'rsut':
            if (e.target.value) {
              const timeStamp = new Date(e.target.value).getTime()
              this.configurationData[e.target.name] = timeStamp
            } else {
              this.configurationData[e.target.name] = undefined
            }
            break
        }
      },
      inputDelayTime (e) {
        if (!this.configurationData) return
        this.configurationData.rsud = e.target.value * 60
      },
      clickConfirmUpdateConfigurationButton (imei, configuration) {
        updateDeviceConfiguration(imei, configuration).then(res => {
          if (res.data.code === 0) {
            this.showConfigDialog = false
            this.$message.success(this.$t('common.updateSuccess'))
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      getSpecifiedDeviceType (type) {
        return getDeviceTypeStr(this, type)
      },
      getSpecifiedLocationType (type) {
        return getLocationTypeStr(this, type)
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  
  .el-table {
    margin: 0;
    border-radius: 10px;
    border: 1px solid #00A2E9;
    /deep/ td {
      padding: 0;
      border: 0;
    }
  }
  
  .deviceInfoList {
    margin: 0 auto;
    position: absolute;
    z-index: 11;
  }
  
  .closeDeviceInfoListButton {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 18px;
    font-size: 16px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: #00A2E9;
    text-align: center;
    z-index: 10;
  }
  
  .listInfoTipBtn {
    cursor: pointer;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    color: white;
    position: absolute;
    background-color: rgb(0, 162, 233);
    text-align: center;
    z-index: 8;
    border-radius: 5px;
    right: 20px;
    padding: 0 10px;
    bottom: 30px;
  }
  
  /*.listTipBtn {*/
  /*  width: 22px;*/
  /*  cursor: pointer;*/
  /*  font-size: 14px;*/
  /*  height: 90px;*/
  /*  font-weight: bold;*/
  /*  color: white;*/
  /*  position: absolute;*/
  /*  background-color: rgb(0, 162, 233);*/
  /*  text-align: center;*/
  /*  border-radius: 5px;*/
  /*  margin-top: 150px;*/
  /*  z-index: 8;*/
  /*  right: 10px;*/
  /*  bottom: 10%;*/
  /*}*/
  
  .listTipBtn {
    cursor: pointer;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    color: white;
    position: absolute;
    background-color: rgb(0, 162, 233);
    text-align: center;
    z-index: 8;
    border-radius: 5px;
    left: 10px;
    padding: 0 10px;
    bottom: 50px;
  }
  
  .userList {
    position: absolute;
    /*bottom: 5%;*/
    top: 10%;
    right: 5px;
    width: 150px;
    z-index: 9;
  }
  
  .itemBtm button {
    margin-left: 10px;
  }
  
  .mapContainer.collapseList {
    width: 100%;
  }
  
  .mapContainer {
    height: 100%;
    width: calc(100% - 310px);
    float: left;
  }
  
  /*.baiduMap {*/
  /*  height: 100%;*/
  /*  width: calc(100% - 310px);*/
  /*  float: left;*/
  /*}*/
  
  .baidu-map {
    width: 100%;
    height: 100%;
  }
  
  .collapseImg.collapseList {
    left: 0;
  }
  
  .collapseImg {
    position: absolute;
    top: 50%;
    left: 260px;
    border: 1px solid #ddd;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    z-index: 10;
  }
  
  .collapseImg:hover {
    border: 1px solid orange;
  }
  
  .el-collapse-item__content {
    padding-bottom: 10px;
  }
  
  .groupItem.active {
    background-color: #fff5ec;
    border: 1px #ffe3c7 solid;
  }
  
  .itemBtm .el-menu.el-menu--horizontal  {
    text-align: right;
    overflow: hidden;
  }
  
  .itemBtm button .el-menu.el-menu--horizontal  {
    font-size: 9px;
    float: right;
  }
  
  .itemBtm .el-menu.el-menu--horizontal {
    float: right;
  }
  
  .el-menu.el-menu--horizontal {
    display: inline-block;
    border: 0;
    background-color: transparent;
  }
  
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: transparent;
  }
  
  .el-menu--horizontal > .el-submenu .el-submenu__title {
    height: unset!important;
    line-height: unset!important;
    font-size: 9px!important;
    padding: 0px 0!important;
    margin-left: 2px!important;
    color: #00A2E9!important;
  }
  
  .itemTop.on {
    color: #00CC33;
  }
  
  .itemTop {
    overflow: hidden;
  }
  
  .groupItem {
    overflow: hidden;
    cursor: pointer;
  }
  
  .groupItem .codeName {
    width: 130px;
  }
  
  .itemTop > div {
    float: left;
  }
  
  .el-tabs__header {
    margin: 0;
  }
  
  .el-tabs__nav-wrap {
    background-color: #f6f7fb;
    text-align: center;
    padding-left: 10px;
  }
  
  .addGroupWarp {
    padding: 8px 0;
  }
  
  .btnGroup button {
    padding: 8px 8px;
  }
  
  .btnGroup {
    text-align: right;
  }
  
  .cancel {
    margin-left: 2px;
  }
  
  .el-input__prefix,
  .el-input__suffix {
    top: -6px;
  }
  
  .el-input__inner {
    height: 28px;
  }
  
  .searchInput {
    width: 94%;
    margin: 0 auto;
  }
  
  .location {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .deviceList {
    width: 300px;
    height: 100% !important;
    float: left;
    background-color: #FFFFFF;
    border-radius: 10px 0 0 10px;
  }
  
  .deviceList.collapseList {
    display: none;
  }
  
  .title {
    padding: 8px 10px;
    font-size: 15px;
    overflow: hidden;
  }
  
  .addGroup {
    float: right;
  }
  
  .timeClass {
    font-weight: bold;
    color: black;
    background: #dddddd;
    position: absolute;
    text-align: center;
    z-index: 8;
    border-radius: 5px;
    left: 33%;
    top: 2.5%;
  }
  
  .commandFormClass {
    width: 100% !important;
  }
  
  .remoteControlCard {
    width: 100%;
  }
  
  .el-input {
    width: 100px !important;
  }
  
  .el-select {
    width: 100px !important;
  }
  
  .sendMessage {
    width: 300px !important;
  }
  
  .sendBtn {
    background: url('../../assets/send-icon-32.png') no-repeat 15px;
    border: none;
    height: 30px;
    width: 60px;
  }
  
  .searchImei {
    width: 100% !important;
  }
  
  .wideInput {
    width: 150px !important;
  }
  
  .operation {
    color: #108cf8;
    margin-left: 10px;
  }
  
  .statusOnline {
    border:1px solid red;
    width:4px;
    height:4px;
    background-color: red;
    margin-top: 6px;
    border-radius: 3px;
  }
  
  .statusRun {
    border:1px solid green;
    width:4px;
    height:4px;
    background-color: green;
    margin-top: 6px;
    border-radius: 3px;
  }
  
  .statusOffline {
    border:1px solid #fcd820;
    width:4px;
    height:4px;
    background-color: #fcd820;
    margin-top: 6px;
    border-radius: 3px;
  }
  
  .offlineText {
    color: #fcd820;
    font-size: xx-small;
  }
  
  .onlineText {
    color: red;
    font-size: xx-small;
  }
  
  .runText {
    color: green;
    font-size: xx-small;
  }
  
  .totalText {
    font-size: xx-small;
  }
  
  .treeIcon {
    height: 16px;
    width: 16px;
  }
  
  .itemClass {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
  }
  
  .tabWrap {
    height: calc(100% - 112px);
    overflow: scroll;
  }
  
  /deep/ .el-dialog {
    border-radius: 20px;
  }
  /deep/ .el-dialog__header{
    background-color: #00A2E9;
    border-radius: 20px 20px 0 0;
  }
  /deep/ .el-dialog__header .el-dialog__title {
    color: white;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 20px 20px;
    color: black;
    font-size: 12px;
  }
  
  /deep/ .el-dialog__body input {
    width: 80px;
    font-size: 12px;
    margin: 1px 0;
  }
  
  /deep/ .el-dialog_body span {
    font-size: 12px;
    color: #232423;
  }
  
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
  }
  .el-form-item {
    height: 24px;
    margin: 0;
    background-color: rgb(118, 103, 29);
  }
  
  </style>
  